import FormValidator from './form-validator';
import LabelAnimation from './label-animation';
import InputMaskModule from './mask-input';
import { AnalyticsConstants, getNodeType, recordClickEvent, recordPageView } from './analytics';

class InitiateOtpModule {
  execute () {
    const form = document.querySelector('form');
    const aside = document.querySelector('aside');
    const submitButton = form.querySelector('.btn-primary');
    const secondaryButton = form.querySelector('.btn-secondary');

    const formValidator = new FormValidator(form, submitButton, secondaryButton);
    formValidator.setup();

    const labelAnimation = new LabelAnimation(form);
    labelAnimation.setup();
    submitButton.disabled = true;

    const pageFunction = form.getAttribute(AnalyticsConstants.ANALYTICS_PAGE_NAME_KEY);
    const flashMsgAnalyticEventType = aside != null ? aside.getAttribute(AnalyticsConstants.ANALYTICS_PAGE_NAME_KEY) : null;
    let pageSubFunction;
    let pageName;

    if (flashMsgAnalyticEventType != null && flashMsgAnalyticEventType === 'start_unconfirmed_phone') {
      pageSubFunction = 'otp';
      pageName = 'start_unconfirmed_phone';
    } else {
      pageSubFunction = document.getElementById('flash-message') ? 'otp_invalid_selection' : 'otp';

      // TODO: Code clean up no longer a screen with a list of phones
      pageName = document.getElementById('phone-number-available') ? 'start' : 'start_no_phone';
    }

    recordPageView({
      pagefunction: pageFunction,
      pagesubfunction: pageSubFunction,
      pagename: pageName
    });
    // Setup dynamic click event for analytics
    form.addEventListener('submit', () => {
      const type = getNodeType(submitButton);
      const otpTextOption = document.getElementById('otp-text');
      const title = otpTextOption && otpTextOption.checked ? 'send_otp_text' : 'send_otp_call';
      recordClickEvent({ type, title });
    });
    window.addEventListener('keypress', event => {
      if (submitButton.disabled === false && event.key === 'Enter' && !document.activeElement.classList.contains('btn-secondary')) {
        event.preventDefault();
        submitButton.click();
      }
    });
  }

  unlistedPhoneNumber () {
    window.addEventListener('load', function () {
      const form = document.querySelector('form');
      const submitButton = form.querySelector('.btn-primary');
      document.getElementById('phone-number').addEventListener('change', function () {
        const selValue = this.value;
        if (selValue === 'ULPN') {
          document.getElementById('enter-phone-number').style.display = 'block';
          document.getElementById('phone-number-header').style.display = 'block';
          const enteredPhoneNumber = document.getElementById('enteredPhoneNumber').value;
          if (enteredPhoneNumber === null || enteredPhoneNumber === '' || document.getElementById('flash-message') !== null) {
            submitButton.disabled = true;
          }
        } else {
          document.getElementById('enter-phone-number').style.display = 'none';
        }
      });

      let isPhoneNumberAvailable = !!document.getElementById('phone-number-available');
      if (!isPhoneNumberAvailable || (document.getElementById('flash-message') !== null)) {
        document.getElementById('enter-phone-number').style.display = 'block';
        const enteredPhoneNumber = document.getElementById('enteredPhoneNumber').value;
        if (enteredPhoneNumber === null || enteredPhoneNumber === '' || document.getElementById('flash-message') !== null) {
          submitButton.disabled = true;
        }
        const length = document.getElementById('phone-number').options.length;
        if (document.getElementById('flash-message') !== null) {
          document.getElementById('phone-number').options[length - 1].selected = true;
          document.getElementById('enteredPhoneNumber').value = window.sessionStorage.getItem('enteredPhoneNumber');
          submitButton.disabled = true;
        }
      } else {
        document.getElementById('phone-number').options[0].selected = true;
        document.getElementById('enter-phone-number').style.display = 'none';
      }
    });
  }

  phoneNumberFormatter () {
    window.addEventListener('load', function () {
      document.getElementById('enteredPhoneNumber').addEventListener('input', function () {
        const enteredPhoneNumber = document.getElementById('enteredPhoneNumber').value;
        const phoneNumberCleaned = ('' + enteredPhoneNumber).replace(/\D/g, '');
        const phoneNumberMatch = phoneNumberCleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        const form = document.querySelector('form');
        const submitButton = form.querySelector('.btn-primary');
        if ((enteredPhoneNumber === null || enteredPhoneNumber === '')) {
          submitButton.disabled = true;
        } else if (phoneNumberMatch.length === 4) {
          submitButton.disabled = false;
        }
        document.getElementById('phone-number-header').style.display = 'block';
        window.sessionStorage.setItem('enteredPhoneNumber', '(' + phoneNumberMatch[1] + ')' + '-' + +phoneNumberMatch[2] + '-' + phoneNumberMatch[3]);
        document.getElementById('enteredPhoneNumber').value = '(' + phoneNumberMatch[1] + ')' + '-' + +phoneNumberMatch[2] + '-' + phoneNumberMatch[3];
      });
    });
  }

  inputMasking () {
    new InputMaskModule().execute();
  }
}

window.IGNITE['InitiateOtpModule'] = InitiateOtpModule;
export default InitiateOtpModule;
