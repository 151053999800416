import PasswordValidators from './password-validators';
import FormValidator from './form-validator';

class PasswordValidationBinder {
  constructor (formValidator, passwordField, confirmPasswordField, lengthCheck, caseCheck, numberCheck) {
    this.formValidator = formValidator;
    this.fields = {
      passwordField,
      confirmPasswordField,
      lengthCheck,
      caseCheck,
      numberCheck
    };
  }

  bindValidators () {
    const passwordFieldValidators = [
      () => PasswordValidators.passwordEmptyValidation(this.fields.passwordField),
      () => PasswordValidators.passwordLengthValidation(this.fields.passwordField),
      () => PasswordValidators.passwordCaseValidation(this.fields.passwordField),
      () => PasswordValidators.passwordNumberValidation(this.fields.passwordField),
      () => PasswordValidators.passwordSpecialCharactersValidation(this.fields.passwordField)
    ];

    const confirmPasswordFieldValidators = [
      () => PasswordValidators.passwordEmptyValidation(this.fields.confirmPasswordField),
      () => PasswordValidators.passwordLengthValidation(this.fields.confirmPasswordField),
      () => PasswordValidators.passwordCaseValidation(this.fields.confirmPasswordField),
      () => PasswordValidators.passwordNumberValidation(this.fields.confirmPasswordField),
      () => PasswordValidators.passwordSpecialCharactersValidation(this.fields.confirmPasswordField),
      () => PasswordValidators.passwordEqualityValidation(this.fields.passwordField, this.fields.confirmPasswordField)
    ];

    this.formValidator.registerCustomInputValidations(this.fields.passwordField, passwordFieldValidators);

    this.formValidator.registerCustomInputValidations(this.fields.confirmPasswordField, confirmPasswordFieldValidators);

    let prevMatchCount = 0;
    this.fields.passwordField.addEventListener('input', () => {
      if (this.fields.lengthCheck) {
        FormValidator.toggleCheckmarkValidityForPwd(this.fields.lengthCheck, PasswordValidators.passwordLengthValidation(this.fields.passwordField).valid, this.fields.passwordField);
      }

      if (this.fields.caseCheck) {
        FormValidator.toggleCheckmarkValidityForPwd(this.fields.caseCheck, PasswordValidators.passwordCaseValidation(this.fields.passwordField).valid, this.fields.passwordField);
      }

      if (this.fields.numberCheck) {
        FormValidator.toggleCheckmarkValidityForPwd(this.fields.numberCheck, PasswordValidators.passwordNumberValidation(this.fields.passwordField).valid, this.fields.passwordField);
      }
      const matchedCount = document.getElementById('password-requirements').getElementsByClassName('valid').length;
      if (matchedCount > 0 && matchedCount !== prevMatchCount) {
        updateAlert(matchedCount + ' of 3 requirements met');
        prevMatchCount = matchedCount;
      } else if (matchedCount === 0) {
        updateAlert('');
        prevMatchCount = 0;
      }
    });
    this.fields.confirmPasswordField.addEventListener('input', () => {
      const lengthValid = PasswordValidators.passwordLengthValidation(this.fields.confirmPasswordField).valid;
      const caseValid = PasswordValidators.passwordCaseValidation(this.fields.confirmPasswordField).valid;
      const numberValid = PasswordValidators.passwordNumberValidation(this.fields.confirmPasswordField).valid;

      if (lengthValid || caseValid || numberValid) {
        this.fields.confirmPasswordField.setAttribute('aria-invalid', 'true');
        this.fields.confirmPasswordField.setAttribute('aria-describedby', 'conPwdError');
      } else {
        this.fields.confirmPasswordField.setAttribute('aria-invalid', 'false');
        this.fields.confirmPasswordField.setAttribute('aria-describedby', 'password-requirements-describe');
      }
    });
  }
}

function updateAlert (msg) {
  const oldAlert = document.getElementById('alert');
  const content = document.createTextNode(msg);
  if (oldAlert.hasChildNodes()) {
    const existingChild = oldAlert.firstChild;
    oldAlert.replaceChild(content, existingChild);
  } else {
    oldAlert.appendChild(content);
  }
  if (msg.length > 0) {
    document.getElementById('new-password').setAttribute('aria-describedby', 'pwdError');
  } else {
    document.getElementById('new-password').setAttribute('aria-describedby', 'password-requirements');
  }
}

export default PasswordValidationBinder;
