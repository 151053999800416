/**
 * Initializes the analytics object with base constants, standard
 * across all login pages
 */
export const initializeAnalytics = (sessionId) => {
  window._SFDDL = window._SFDDL || {};
  window._SFDDL.base = {
    event_type: 'pageview',
    site_identifier: 'Web',
    app_type: 'webapp',
    country: 'us',
    session_id: sessionId,
    existing_user: 'N'
  };
  startClickListener();
};

/**
 * @type {Object.<string, string>} Constants used for analytics
 */
export const AnalyticsConstants = {
  ANALYTICS_PAGE_NAME_KEY: 'data-analytics',
  ANALYTICS_TITLE_KEY: 'data-analytics-title',
  ANALYTICS_TYPE_KEY: 'data-analytics-type'
};

const inputTypesTrackedOnFocus = {
  email: true,
  password: true,
  text: true
};

/**
 * @param {HTMLElement} element
 * @returns {boolean} true if we should be tracking the element onfocus instead of onclick
 */
const shouldBeTrackedOnFocus = element => {
  return element.nodeName === 'INPUT' && inputTypesTrackedOnFocus[element.getAttribute('type')];
};

/**
 * Records a click event if the element clicked contains a data-analytics-title attribute.
 * In order to add click analytics to an element, it needs to be on a page currently recording
 * page views and it needs to have a data-analytics-title attribute.
 * Note that if the analytics title of an element is dynamic (e.g. the login button's title
 * depends on the state of the 'Remember Username' checkbox), then those listeners should
 * be added to its page's script file and call recordClickEvent manually.
 */
const startClickListener = () => {
  window._SFDDL.click = [];

  const onClickEvent = shouldBeTracked => event => {
    const clickTarget = event.target;
    const title = clickTarget.getAttribute(AnalyticsConstants.ANALYTICS_TITLE_KEY);
    if (shouldBeTracked(clickTarget) && title) {
      recordClickEvent({ type: getNodeType(clickTarget), title });
    }
  };

  // Only record the click event if the element should not be tracked onfocus instead of onclick
  window.addEventListener('click', onClickEvent(element => !shouldBeTrackedOnFocus(element)));

  document.querySelectorAll('input').forEach(input => {
    // Only record the focus event if the element should be tracked onfocus instead of onclick
    input.addEventListener('focus', onClickEvent(shouldBeTrackedOnFocus));
  });
};

/**
 * @param {HTMLElement} element
 * @returns {string} An element's data-analytics-type attribute, or, if none exists,
 * maps from an element's type for the default type value.
 */
export const getNodeType = element => {
  const elementType = element.getAttribute(AnalyticsConstants.ANALYTICS_TYPE_KEY);
  if (elementType) {
    return elementType;
  }
  switch (element.nodeName) {
    case 'A':
      return 'link';
    case 'BUTTON':
      return 'button';
    case 'INPUT':
      return element.getAttribute('type') === 'checkbox' ? 'radio-button' : 'field';
    default:
      return '';
  }
};

const getPageInfo = () => {
  return window._SFDDL.pageInfo;
};

/**
 * Records the page view for analytics with the page-specific info
 * @param {!Object.<string>} clickInfo Includes the properties type and title
 */
export const recordClickEvent = clickInfo => {
  const pageInfo = getPageInfo() || {};
  window._SFDDL.click.push({
    pagefunction: pageInfo.pagefunction,
    pagename: pageInfo.pagename,
    ...clickInfo
  });
  dispatchTrackingEvent('customClickEvent');
};

/**
 * @enum {string} Specifies whether the state of the user is logged in or not
 */
export const AuthenticatedState = {
  LOGGED_IN: 'LOGGED_IN',
  NOT_LOGGED_IN: 'NOT_LOGGED_IN'
};

/**
 * Page info that is standard across all login pages
 */
const defaultPageInfo = {
  SiteIdentifier: 'BANK',
  account_type: 'generic',
  pagekind: 'login_registration',
  pagesubfunction: '',
  attr: ''
};

/**
 * Records the page view for analytics with the page-specific info
 * @param {!Object.<string>} pageInfo
 * @param {string} pageInfo.pagefunction
 * @param {?string} pageInfo.pagesubfunction
 * @param {AuthenticatedState=} authenticatedState Defaults to NOT_LOGGED_IN
 */
export const recordPageView = (pageInfo, authenticatedState = AuthenticatedState.NOT_LOGGED_IN) => {
  window._SFDDL.pageInfo = { ...defaultPageInfo, ...pageInfo };
  if (authenticatedState === AuthenticatedState.LOGGED_IN) {
    window._SFDDL.base.existing_user = 'Y';
  }
  dispatchTrackingEvent('customPageView');
  // dispatchTrackingEvent('customPageview');
};

/**
 * @param {string} eventName Dispatches a browser event with the appropriate eventName when the event
 * data is ready to be recorded
 */
const dispatchTrackingEvent = eventName => {
  let pageTrackingEvent;
  // IE11 polyfill
  if (typeof window.Event === 'function') {
    pageTrackingEvent = new window.Event(eventName);
  } else {
    pageTrackingEvent = document.createEvent('Event');
    pageTrackingEvent.initEvent(eventName, false, false);
  }
  document.dispatchEvent(pageTrackingEvent);
};
/**
  *Records the page view for analytics with error messages
  */
document.addEventListener('DOMContentLoaded', function () {
  const flashMessage = document.getElementById('flash-message');
  if (flashMessage) {
    const analyticsType = flashMessage.getAttribute('data-analytics');
    if (analyticsType && flashMessage.classList.contains('error-flash')) {
      window._SFDDL.pageInfo.MessageType = analyticsType;
    }
  }
});

window.IGNITE['AnalyticsModule'] = { initializeAnalytics };
