import formHelper from './form-helper';

class FormValidator {
  constructor (form, submitButton, secondaryButton) {
    this.form = form;
    this.submitButton = submitButton;
    this.secondaryButton = secondaryButton;
    this.inputs = formHelper.focusableInputs(form.elements);
    this.customInputValidations = {};
  }

  static executeCustomInputValidators (input, listOfValidators) {
    const validationErrors = [];
    const validationErrorsToAlert = [];

    // Reset the error message to the default error message
    const wrapper = input.closest('[data-form-element]');
    const errorHtml = wrapper.querySelector('p.error');
    const displayHtml = wrapper.querySelector('p.screenreader-only');
    let defaultErrorMessage = null;
    if (errorHtml) {
      defaultErrorMessage = errorHtml.dataset['defaultErrorMessage'];
      errorHtml.innerHTML = defaultErrorMessage;
    }
    if (displayHtml) {
      defaultErrorMessage = displayHtml.dataset['defaultErrorMessage'];
      displayHtml.innerHTML = defaultErrorMessage;
    }

    // Reset the input's customValidity to be 'valid' and clear the custom error message.
    input.setCustomValidity('');
    for (let validator of listOfValidators) {
      const validationResult = validator();
      if (!validationResult.valid) {
        // Replace the default error message and break the loop ONLY if the validator has a non-empty custom error message.
        // NOTE: Custom validation error messages will always get priority over default error messages!
        if (validationResult.customValidationMessage) {
          const errorHtml = wrapper.querySelector('p.error');
          const displayHtml = wrapper.querySelector('p.screenreader-only');
          if (errorHtml) {
            errorHtml.innerText = '';
            validationErrors.push(validationResult.customValidationMessage + '\n');
            errorHtml.innerText = validationErrors.toString().replaceAll(',', '');
            // Set the custom validity error message (used in error tooltip) to be the custom validation message if it exists.
            input.setCustomValidity(validationErrors.toString().replaceAll(',', ''));
          }
          if (displayHtml) {
            displayHtml.innerText = '';
            validationErrorsToAlert.push(validationResult.customValidationMessage + ' ');
            displayHtml.innerText = validationErrorsToAlert.toString().replaceAll(',', '');
            // Set the custom validity error message (used in error tooltip) to be the custom validation message if it exists.
            input.setCustomValidity(validationErrorsToAlert.toString().replaceAll(',', ''));
          }
        } else if (defaultErrorMessage != null && defaultErrorMessage !== '') {
          input.setCustomValidity(defaultErrorMessage);
        } else {
          input.setCustomValidity('Invalid input');
        }
      }
    }
  }

  addInputValidationListeners (input) {
    const wrapper = input.closest('[data-form-element]');

    input.addEventListener('blur', () => {
      executeCustomValildatorsAndCheckSubmit();
      if (!input.validity.valid) {
        wrapper.classList.add('invalid');
      }
    });

    /*
     To prevent a race condition where input and change are fired simultaneously,
     create a lock and only execute the event if unlocked.

     The lambda is idempotent, so it is fine if it fires twice.
     */
    let lock = false;
    const executeCustomValildatorsAndCheckSubmit = () => {
      if (!lock) {
        lock = true;
        wrapper.classList.remove('invalid');

        if (input.name in this.customInputValidations) {
          const customInputValidators = this.customInputValidations[input.name];
          FormValidator.executeCustomInputValidators(input, customInputValidators);
        }

        this.checkSubmitButton();
        lock = false;
      }
    };

    input.addEventListener('input', executeCustomValildatorsAndCheckSubmit);
    input.addEventListener('change', executeCustomValildatorsAndCheckSubmit);
  }

  setup () {
    this.inputs.forEach(input => {
      this.addInputValidationListeners(input);

      // Set the default error message for all inputs
      const wrapper = input.closest('[data-form-element]');
      const errorHtml = wrapper.querySelector('p.error');
      if (errorHtml && errorHtml.innerText === '') {
        errorHtml.innerText = errorHtml.dataset['defaultErrorMessage'];
      }
    });

    this.submitButton.addEventListener('click', event => {
      event.preventDefault();
      this.performSubmit();
    });

    if (this.secondaryButton != null) {
      this.secondaryButton.addEventListener('click', event => {
        event.preventDefault();
        this.performSecondaryAction();
      });
    }

    this.form.addEventListener('submit', event => {
      event.preventDefault();
    });

    this.checkSubmitButton();
  }

  registerCustomInputValidations (input, listOfValidators) {
    this.customInputValidations[input.name] = listOfValidators;
  }

  performSubmit () {
    if (this.form.checkValidity()) {
      this.inputs.forEach(input => {
        input.readOnly = true;
      });
      this.submitButton.classList.add('loading');
      this.submitButton.disabled = true;
      this.form.submit();
    }
  }

  performSecondaryAction () {
    this.secondaryButton.classList.add('loading');
    this.secondaryButton.disabled = true;

    if (this.secondaryButton.formAction) {
      this.form.action = this.secondaryButton.formAction;
    }

    this.form.submit();
  }

  checkSubmitButton () {
    this.submitButton.disabled = !this.form.checkValidity();
  }

  static toggleCheckmarkValidity (checkmark, valid) {
    if (valid) {
      if (!checkmark.classList.contains('valid')) {
        checkmark.ariaLive = 'polite';
      }
      checkmark.classList.add('valid');
    } else {
      if (checkmark.classList.contains('valid')) {
        checkmark.ariaLive = 'off';
      }
      checkmark.classList.remove('valid');
    }
  }
  static toggleCheckmarkValidityForPwd (checkmark, valid, passwordfield) {
    if (valid) {
      if (!checkmark.classList.contains('valid')) {
        checkmark.hidden = 'false';
        passwordfield.setAttribute('aria-invalid', 'true');
      }
      checkmark.classList.add('valid');
    } else {
      if (checkmark.classList.contains('valid')) {
        checkmark.hidden = 'true';
        passwordfield.setAttribute('aria-invalid', 'false');
      }
      checkmark.classList.remove('valid');
    }
  }
}

export default FormValidator;
